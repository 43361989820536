import React from "react";
import PropTypes from "prop-types";
import ClearFiltersButton from "./ClearFiltersButton";

// SearchUI components
import { Facet, Sorting } from "@elastic/react-search-ui";
import SORT_OPTIONS from "../searchui/SortOptions";

function SearchSideContent({ wasSearched, initial_loader }) {

  return (
    <div>
      {!initial_loader && (
        <div>
          {wasSearched && (
            <>
              <ClearFiltersButton />
              <Sorting label={"Sort by"} sortOptions={SORT_OPTIONS} />
            </>
          )}
          {wasSearched && (
            <div className="sui-facet">
              <div className="sui-facet__title">Valid as of</div>
              <div className="css-1pcexqc-container sui-select">
                <div className="css-0 sui-select__control facet-valid">
                  <div className="css-1hwfws3 sui-select__value-container sui-select__value-container--has-value">
                    <div className="css-dvua67-singleValue sui-select__single-value">January 13<sup style={{fontSize: "0.8em"}}>th</sup>, 2025</div>
                  </div>
                  <div className="css-1wy0on6 sui-select__indicators">
                    <span className="css-0 sui-select__indicator-separator"></span>
                    <div aria-hidden="true" className="css-0 sui-select__indicator sui-select__dropdown-indicator"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Facet
            field="new_addition"
            label="New Addition"
            filterType="any"
            isFilterable={true}
          />
          <Facet
            field="banned_oic1"
            label="Affected By May 2020 OIC"
            filterType="any"
            isFilterable={true}
          />
          <Facet
            field="banned_oic2"
            label="Affected By Dec 2024 OIC"
            filterType="any"
            isFilterable={true}
          />
          <Facet
            field="type"
            label="Type"
            filterType="any"
            isFilterable={true}
          />
          <Facet
            field="action"
            label="Action"
            filterType="any"
            isFilterable={true}
          />
          <Facet
            field="legal_classification"
            label="Legal Classification"
            filterType="any"
            isFilterable={true}
          />
          <Facet
            field="calibres"
            label="Calibres"
            filterType="any"
            isFilterable={true}
          />
          <Facet
            field="barrel_lengths"
            label="Barrel Length (mm)"
            filterType="any"
            isFilterable={true}
          />
          <Facet
            field="make"
            label="Make"
            filterType="any"
            isFilterable={true}
          />
          <Facet
            field="country_of_manufacturer"
            label="Country of Manufacturer"
            filterType="any"
            isFilterable={true}
          />
          <Facet
            field="oic_legal_authorities"
            label="OIC Category"
            filterType="any"
            isFilterable={true}
          />
          <Facet
            field="reclassified_date"
            label="Date Added/Reclassified (OIC Firearms)"
            filterType="any"
            isFilterable={true}
          />
        </div>
      )}
    </div>
  );
}

export default SearchSideContent;

SearchSideContent.propTypes = {
    wasSearched: PropTypes.any,
    initial_loader: PropTypes.bool,
};
