import React from "react";
import PropTypes from "prop-types";
import armTheme from "../styles/armTheme";

// Reducer objects
import { CHANGE_SITE_INFO } from "../constants/actionTypes";

// Material UI Components
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

// Snackbar
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// Import logo
import logo from "../assets/logo_dark.png";

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "center",
    padding: "50px 15px 32px 15px",
    [theme.breakpoints.up("sm")]: {
      padding: "50px 24px 32px 24px",
    },
    borderRadius: "0",
  },
  menuDiv: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "40px",
    flexWrap: "wrap",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  logo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textDecoration: "none",
    margin: "0px auto 40px auto",
    width: "290px"
  },
  logoimg: {
    height: "50px",
    width: "84.13px",
    marginRight: "8px"
  },
  logotext: {
    fontFamily: "Black Ops One, Roboto",
    color: armTheme.colours.primary.main,
  },
  linktext: {
    whiteSpace: "nowrap",
  },
  donate: {
    color: armTheme.colours.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

function Footer({ dispatchSI }) {
  const classes = useStyles();

  function handleItemSelect(event, page) {
    event.preventDefault();
    dispatchSI({type: CHANGE_SITE_INFO, payload: {open: true, page: page}});
  }

  const handleClose = () => {
    setOpen(false);
  };

  var snackbarSeen = localStorage.getItem('snackbarSeenBan');
  var snackOpen = false;
  if (!snackbarSeen) {
    localStorage.setItem('snackbarSeenBan', true);
    snackOpen = true;
  } else {
    snackOpen = false;
  }

  const [open, setOpen] = React.useState(snackOpen);

  return (
    <Paper elevation={2} className={classes.root}>
      <div className={classes.logo}>
        <img className={classes.logoimg} src={logo} alt="Logo" />
        <Typography className={classes.logotext} variant="h4" component="h1">Armalytics</Typography>
      </div>
      <div className={classes.menuDiv}>
        <Link href="#" color="primary" onClick={(e) => handleItemSelect(e, "banMessage")}>
          <Typography>
            Message on OICs
          </Typography>
        </Link>
        <Link href="#" color="primary" onClick={(e) => handleItemSelect(e, "about")}>
          <Typography>
            About
          </Typography>
        </Link>
        <Link href="#" color="primary" onClick={(e) => handleItemSelect(e, "contact")}>
          <Typography>
            Contact
          </Typography>
        </Link>
        <a className={classes.donate} rel="noopener noreferrer" href="https://donorbox.org/support-armalytics" target="_blank">
          <Typography>
            Donate
          </Typography>
        </a>
        <Link href="#" color="primary" onClick={(e) => handleItemSelect(e, "disclaimer")}>
          <Typography>
            Disclaimer
          </Typography>
        </Link>
        <Link href="#" color="primary" onClick={(e) => handleItemSelect(e, "terms")}>
          <Typography>
            Terms &amp; Conditions
          </Typography>
        </Link>
        <Link href="#" color="primary" onClick={(e) => handleItemSelect(e, "privacy")}>
          <Typography>
            Privacy Policy
          </Typography>
        </Link>
      </div>
      <Typography variant="caption"><strong>Copyright &copy; {new Date().getFullYear()} Armalytics, Inc. All rights reserved.</strong></Typography>
      <Snackbar
          open={open}
          onClose={handleClose}
          message={
            <React.Fragment>
              <Typography variant="body">ATTENTION: Firearms tagged with &quot;Affected by OIC&quot; have been partially or wholly prohibited by the 2020 or 2024 OICs.</Typography>
            </React.Fragment>
          }
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
    </Paper>
  );
}

export default Footer;

Footer.propTypes = {
    dispatchSI: PropTypes.func,
};
